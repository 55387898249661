<template>
  <div :class="['relative z-10 flex-shrink-0 flex h-79p px-6 py-5 bg-white', {
    'border-t': borderTop
  }]">
    <div class="flex-1 flex justify-between">
      <div class="flex-1 flex">
        <div class="w-full flex md:ml-0">
          <label for="search_field" class="sr-only">{{ $t('dataTableSearch.search') }}</label>
          <div class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none cursor-pointer" @click="searchValue = ''">
              <svg :class="['h-5 w-5', {
                'opacity-50': onFocus
              }]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="onFocus ? 'bg-gray-500' : 'currentColor' "
              >
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <input id="search_field" name="search_field"
                   class="block h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                   :placeholder="$t('global.dataTableSearch.search')"
                   type="text"
                   v-model="searchValue"
                   @focus="onFocus = true"
            >
          </div>
        </div>
        <GroupByAction v-if="groupByAction" />
      </div>
    </div>
  </div>
</template>

<script>

import GroupByAction from '@/components/DataTableSearch/GroupByAction/GroupByAction';

export default {
  name: 'DataTableSearch',
  components: {
    GroupByAction
  },
  props: {
    groupByAction: {
      type: Boolean,
      'default': false
    },
    borderTop: {
      type: Boolean,
      'default': true
    }
  },
  data() {
    return {
      searchValue: '',
      onFocus: false
    }
  },
  watch: {
    searchValue(newValue) {
        this.$emit('handleSearchTable', newValue.toLowerCase().trim());
    }
  },
  methods: {
    removeOnFocus() {
      this.onFocus = false;
    }
  }
}
</script>

<style scoped>

</style>

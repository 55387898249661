export default (context, key, sorting) => {
    if (sorting) {
        // eslint-disable-next-line no-nested-ternary
        if (['total', 'total_vat_included', 'id', 'netvisor_id'].includes(key)) {
            context.dataTable.sort((a, b) => (Number(b.data[key]) > Number(a.data[key])) ? 1 : ((Number(a.data[key]) > Number(b.data[key])) ? -1 : 0))
        } else if (key ==='customer') {
            context.dataTable.sort((a, b) => (String(b.data.task.customer?.name).toUpperCase() > String(a.data.task.customer?.name).toUpperCase()) ? 1 : ((String(a.data.task.customer?.name).toUpperCase() > String(b.data.task.customer?.name).toUpperCase()) ? -1 : 0))
        } else if (key ==='vendor') {
            context.dataTable.sort((a, b) => (String(b.vendor.name).toUpperCase() > String(a.vendor.name).toUpperCase()) ? 1 : ((String(a.vendor.name).toUpperCase() > String(b.vendor.name).toUpperCase()) ? -1 : 0))
        } else if (['name', 'unit', 'includes_vat'].includes(key)) {
            context.dataTable.sort((a, b) => (String(b[key]).toUpperCase() > String(a[key]).toUpperCase()) ? 1 : ((String(a[key]).toUpperCase() > String(b[key]).toUpperCase()) ? -1 : 0))
        } else if (key ==='address') {
            context.dataTable.sort((a, b) => (String(b.address).toUpperCase() > String(a.address).toUpperCase()) ? 1 : ((String(a.address).toUpperCase() > String(b.address).toUpperCase()) ? -1 : 0))
        } else if (key ==='contact') {
            context.dataTable.sort((a, b) => (String(b.contact).toUpperCase() > String(a.contact).toUpperCase()) ? 1 : ((String(a.contact).toUpperCase() > String(b.contact).toUpperCase()) ? -1 : 0))
        } else if (key ==='price') {
            context.dataTable.sort((a, b) => (Number(b.price) > Number(a.price)) ? 1 : ((Number(a.price) > Number(b.price)) ? -1 : 0))
        } else if (key ==='vat') {
            context.dataTable.sort((a, b) => (Number(b.vat) > Number(a.vat)) ? 1 : ((Number(a.vat) > Number(b.vat)) ? -1 : 0))
        } else if (key ==='assigneeName') {
            context.dataTable.sort((a, b) => (String(b.assigneeName).toUpperCase() > String(a.assigneeName).toUpperCase()) ? 1 : ((String(a.assigneeName).toUpperCase() > String(b.assigneeName).toUpperCase()) ? -1 : 0))
        } else if (key ==='task') {
            context.dataTable.sort((a, b) => (String(b.data.task.title).toUpperCase() > String(a.data.task.title).toUpperCase()) ? 1 : ((String(a.data.task.title).toUpperCase() > String(b.data.task.title).toUpperCase()) ? -1 : 0))
        } else if (key ==='created_at') {
            context.dataTable.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        } else if (key === 'title') {
            context.dataTable.sort((a, b) => (String(b.title).toUpperCase() > String(a.title).toUpperCase()) ? 1 : ((String(a.title).toUpperCase() > String(b.title).toUpperCase()) ? -1 : 0))
        } else if (key === 'email') {
            context.dataTable.sort((a, b) => (String(b.email).toUpperCase() > String(a.email).toUpperCase()) ? 1 : ((String(a.email).toUpperCase() > String(b.email).toUpperCase()) ? -1 : 0))
        } else if (key === 'fennoa_dimension_code') {
            context.dataTable.sort((a, b) => (String(b.fennoa_dimension_code).toUpperCase() > String(a.fennoa_dimension_code).toUpperCase()) ? 1 : ((String(a.fennoa_dimension_code).toUpperCase() > String(b.fennoa_dimension_code).toUpperCase()) ? -1 : 0))
        }

                // eslint-disable-next-line no-nested-ternary
    } else {
        // eslint-disable-next-line no-nested-ternary
        // eslint-disable-next-line no-nested-ternary
        if (['total', 'total_vat_included', 'id', 'netvisor_id'].includes(key)) {
            context.dataTable.sort((a, b) => (Number(a.data[key]) > Number(b.data[key])) ? 1 : ((Number(b.data[key]) > Number(a.data[key])) ? -1 : 0))
        } else if (key === 'customer') {
            context.dataTable.sort((a, b) => (String(a.data.task.customer?.name).toUpperCase() > String(b.data.task.customer?.name).toUpperCase()) ? 1 : ((String(b.data.task.customer?.name).toUpperCase() > String(a.data.task.customer?.name).toUpperCase()) ? -1 : 0))
        } else if (key === 'vendor') {
            context.dataTable.sort((a, b) => (String(a.vendor.name).toUpperCase() > String(b.vendor.name).toUpperCase()) ? 1 : ((String(b.vendor.name).toUpperCase() > String(a.vendor.name).toUpperCase()) ? -1 : 0))
        } else if (['name', 'unit', 'includes_vat'].includes(key)) {
            context.dataTable.sort((a, b) => (String(a[key]).toUpperCase() > String(b[key]).toUpperCase()) ? 1 : ((String(b[key]).toUpperCase() > String(a[key]).toUpperCase()) ? -1 : 0))
        } else if (key === 'address') {
            context.dataTable.sort((a, b) => (String(a.address).toUpperCase() > String(b.address).toUpperCase()) ? 1 : ((String(b.address).toUpperCase() > String(a.address).toUpperCase()) ? -1 : 0))
        } else if (key === 'contact') {
            context.dataTable.sort((a, b) => (String(a.contact).toUpperCase() > String(b.contact).toUpperCase()) ? 1 : ((String(b.contact).toUpperCase() > String(a.contact).toUpperCase()) ? -1 : 0))
        } else if (key === 'price') {
            context.dataTable.sort((a, b) => (Number(a.price) > Number(b.price)) ? 1 : ((Number(b.price) > Number(a.price)) ? -1 : 0))
        } else if (key === 'vat') {
            context.dataTable.sort((a, b) => (Number(a.vat) > Number(b.vat)) ? 1 : ((Number(b.vat) > Number(a.vat)) ? -1 : 0))
        } else if (key === 'assigneeName') {
            context.dataTable.sort((a, b) => (String(a.assigneeName).toUpperCase() > String(b.assigneeName).toUpperCase()) ? 1 : ((String(b.assigneeName).toUpperCase() > String(a.assigneeName).toUpperCase()) ? -1 : 0))
        } else if (key === 'task') {
            context.dataTable.sort((a, b) => (String(a.data.task.title).toUpperCase() > String(b.data.task.title).toUpperCase()) ? 1 : ((String(b.data.task.title).toUpperCase() > String(a.data.task.title).toUpperCase()) ? -1 : 0))
        } else if (key === 'created_at') {
            context.dataTable.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        } else if (key === 'title') {
            context.dataTable.sort((a, b) => (String(a.title).toUpperCase() > String(b.title).toUpperCase()) ? 1 : ((String(b.title).toUpperCase() > String(a.title).toUpperCase()) ? -1 : 0))
        } else if (key === 'email') {
            context.dataTable.sort((a, b) => (String(a.email).toUpperCase() > String(b.email).toUpperCase()) ? 1 : ((String(b.email).toUpperCase() > String(a.email).toUpperCase()) ? -1 : 0))
        } else if (key === 'fennoa_dimension_code') {
            context.dataTable.sort((a, b) => (String(a.fennoa_dimension_code).toUpperCase() > String(b.fennoa_dimension_code).toUpperCase()) ? 1 : ((String(b.fennoa_dimension_code).toUpperCase() > String(a.fennoa_dimension_code).toUpperCase()) ? -1 : 0))
        }
    }
}

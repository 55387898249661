<template>
  <div :class="[{'arrow-up transform': arrowUp, 'arrow-down transform': !arrowUp}, rotate]">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#D1D5DB">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CollapseTableIcon',
  props: {
    arrowUp: Boolean,
    rotate: String
  },
  data() {
    return {
      activeClass: 'active',
      errorClass: 'text-danger'
    }
  }
}
</script>

<style scoped>
.arrow-down {
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}
.arrow-up {
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}
</style>

<template>
  <div class="bg-white px-4 py-3 flex items-center justify-center sm:px-6 mt-3.5 mr-3.5">
    <div class="z-0 inline-flex rounded-md flex items-center justify-center relative" v-click-outside="closeDropdown">
      <PaginationPopUp
          :isOpen="isOpen"
          :maxPageCount="maxPageCount"
          :currentPage="currentPage"
          @handleChangePage="handleChangePage"
      />
      <div class="flex items-center justify-between w-500p">
        <div @click="currentPage > 1 && handleNextPrevious(currentPage - 1)">
          <a :class="[`relative inline-flex items-center px-2 py-2 rounded-l-md border-none bg-white text-sm font-medium`, {
            'text-gray-500 cursor-pointer': currentPage > 1,
            'text-gray-300 cursor-not-allowed': currentPage === 1
          }]">
            <span class="sr-only">{{ $t('pagination.previous') }}</span>
           <PaginationPreviousIcon />
          </a>
        </div>
        <div>
          <div class="mt-1">
            <button
                @click="handleOpen"
                type="button"
                class="bg-white relative w-160p rounded-md py-2 text-center cursor-pointer focus:outline-none text-sm font-medium text-gray-500">
                <span>
                  <span>{{ currentPage }}</span>
                    <span class="m-2">of</span>
                  <span>{{ maxPageCount? maxPageCount : 1 }}</span>
                </span>
            </button>
          </div>
        </div>
        <div @click="currentPage < maxPageCount && handleNextPrevious(currentPage + 1)">
          <a :class="[`relative inline-flex items-center px-2 py-2 rounded-l-md border-none bg-white text-sm font-medium`, {
            'text-gray-500 cursor-pointer': currentPage < maxPageCount,
            'text-gray-300 cursor-not-allowed': !count || currentPage === maxPageCount
          }]">
            <span class="sr-only">{{ $t('pagination.next') }}</span>
            <PaginationNextIcon />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';
import PaginationPreviousIcon from '@/components/Icons/PaginationPreviousIcon';
import PaginationNextIcon from '@/components/Icons/PaginationNextIcon';
import PaginationPopUp from '@/components/Main/Pagination/PaginationPopUp';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'Pagination',
  components: {
    PaginationPopUp,
    PaginationNextIcon,
    PaginationPreviousIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    count: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      isOpen: false,
      isScrollUp: false,
      maxPageCount: 0,
      // Default Products count in table
      defaultPageSlice: 30
    }
  },
  watch: {
    count(newValue) {
      this.handlePageMaxCount(newValue);
    }
  },
  mounted() {
    this.handlePageMaxCount(this.count);

    EventBus.$on('resetPage', () => {
      this.currentPage = 1;
    });
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpen() {
        if (!this.count) {
          return;
        }

        this.isOpen = true;
    },
    handleChangePage(selectedPage) {
      this.emitHandlePaginate(selectedPage);
      this.currentPage = selectedPage;
      this.closeDropdown();
    },
    handleNextPrevious(currentPage) {
      this.currentPage = currentPage;
      this.emitHandlePaginate(currentPage);
    },
    emitHandlePaginate(selectedPage) {
      this.$emit('handlePaginate', ((selectedPage - 1) * this.defaultPageSlice), (((selectedPage - 1) * this.defaultPageSlice) + this.defaultPageSlice));
    },
    handlePageMaxCount(count) {
      this.maxPageCount = Math.round(Math.ceil(count / this.defaultPageSlice));
    }
  },
  destroyed() {
    EventBus.$off('resetPage');
  }
}
</script>

<style scoped>

</style>

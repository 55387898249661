<template>
  <div class="relative inline-block text-left" v-click-outside="closeDropdown">
    <div>
      <button @click.prevent="handleToggleDropDown"
              class="whitespace-nowrap block max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-md">
        <span class="text-sm font-medium text-gray-500"><span
            class="whitespace-nowrap block"></span>{{ $t('board.groupBy') }}</span>
        <div class="ml-2">
          <CollapseTableIcon :arrowUp="!isGroupByOpen" :rotate="!isGroupByOpen ? 'rotate-0' : 'rotate-180'" />
        </div>
      </button>
    </div>
    <div v-if="isGroupByOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
      <div class="py-1">
        <a
          href="#"
          v-for="option in options"
          :key="option.key"
          @click.prevent="handleGroupBy(option.label)"
          :class="['block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900', {
            'font-bold': selectedType === option.label
          }]">
          {{ $t(option.title) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { EventBus } from '@/eventBus/event-bus';
import groupByOptions from '@/helpers/groupByOptions';
import CollapseTableIcon from '@/components/Icons/CollapseTableIcon';

export default {
  name: 'GroupByAction',
  components: {
    CollapseTableIcon
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      options: groupByOptions,
      isGroupByOpen: false,
      selectedType: null
    }
  },
  methods: {
    closeDropdown() {
      this.isGroupByOpen = false;
    },
    handleToggleDropDown() {
      this.isGroupByOpen = !this.isGroupByOpen;
    },
    handleGroupBy(type) {
      EventBus.$emit('handleGroupBy', type);
      this.selectedType = type;
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div  v-show="isOpen" class="absolute bottom-17p rounded-md bg-white z-40 w-150p max-h-200p shadow-lg">
    <ul class="max-h-200p rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm pagination">
      <li
          v-for="(item) in maxPageCount"
          @click="$emit('handleChangePage', item)"
          :class="['cursor-pointer select-none relative py-2 text-center', {
                       'text-white bg-primary': currentPage === item,
                       'text-gray-900 hover:bg-gray-50': currentPage !== item
              }]">
            <span :class="[
                'text-sm font-medium', {
                'text-gray-500': currentPage !== item
                }
            ]">
              {{ (item) }} of {{ maxPageCount }}
            </span>
        <span
            v-if="currentPage === item"
            class="text-indigo-600 absolute ml-3 inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="currentPage === item ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PaginationPopUp',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxPageCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.pagination::-webkit-scrollbar {
  display: none;
}
</style>

export default [
    {
        key: 1,
        label: 'customer',
        title: 'global.groupByButton.byCompanies'
    },
    {
        key: 2,
        label: 'members',
        title: 'global.groupByButton.byMembers'
    },
    {
        key: 3,
        label: 'myTask',
        title: 'global.groupByButton.myTasks'
    },
    {
        key: 4,
        label: null,
        title: 'global.groupByButton.byStatus'
    }
];

<template>
  <div class="absolute right-0 top-0.2">
    <svg xmlns="http://www.w3.org/2000/svg" :class="['h-4 w-4', {'arrow-up': arrowUp, 'arrow-down': !arrowUp}]" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ArrowUp',
  props: {
    arrowUp: Boolean
  }
}
</script>

<style scoped>
.arrow-down {
  transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}
.arrow-up {
  transform:rotate(0deg);
  -ms-transform:rotate(0deg);
  -webkit-transform:rotate(0deg);
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}
</style>

import { render, staticRenderFns } from "./PaginationPreviousIcon.vue?vue&type=template&id=2815e28b&scoped=true&"
import script from "./PaginationPreviousIcon.vue?vue&type=script&lang=js&"
export * from "./PaginationPreviousIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2815e28b",
  null
  
)

export default component.exports